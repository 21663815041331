import { Component, Input } from '@angular/core';
import { Job } from '@models/job.model';
import { JobHelperService } from '@services/job-helper.service';
import { OrderHelperService } from '@services/order-helper.service';

@Component({
  selector: 'app-deadline',
  templateUrl: './deadline.component.html',
  styleUrls: ['./deadline.component.css']
})
export class DeadlineComponent {
  @Input() job: Job;
  @Input() isCustomerView?: boolean;

  constructor(
    private jobHelper: JobHelperService,
    private orderHelper: OrderHelperService
  ) { }

  getDeadline(job: Job): Date {
    return job.isSPI() ? this.jobHelper.getSpiDeadline(job, this.isCustomerView) : this.jobHelper.getDeadline(job);
  }

  consentExist(job: Job): boolean {
    return this.getDeadline(job) ? true : false;
  }

  isDeadline(job: Job): boolean {
    let isNotDeadline = true;
    if (this.isDeadlineClose(job)) {
      isNotDeadline = false;
    }
    if (this.isDeadlinePassed(job)) {
      isNotDeadline = false;
    }
    return isNotDeadline;
  }

  isDeadlineClose(job: Job): boolean {
    const deadline = this.getDeadline(job);
    return this.jobHelper.isDeadlineClose(deadline);
  }

  isDeadlinePassed(job: Job): boolean {
    const deadline = this.getDeadline(job);
    return this.jobHelper.isDeadlinePassed(deadline);
  }

  getScheduleDate(job: Job): Date | null {
    return job.scDetails.activationTime
      ? new Date(job.scDetails.activationTime)
      : job.schedules
        ? this.orderHelper.getScheduleActivationTime(job)
        : null;
  }
}
