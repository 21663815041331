export enum CustomerAccess {
  BackgroundCheck = "BACKGROUND_CHECK",
  ReferenceCheck = "REFERENCE_CHECK",
  SecurityScreening = "SECURITY_SCREENING",
  LFD = "LFD",
  SecurityClearance = "SECURITY_CLEARANCE",
  ProtectiveSecurity = "PROTECTIVE_SECURITY",

  //Ta bort efter USÄK deploy
  RiscGradeOrderer = "RISC_GRADE_ORDERER",
  RiscGradeRecipient = "RISC_GRADE_RECIPIENT",
  SecurityScreeningLawIncluded = "SECURITY_SCREENING_LAW_INCLUDED",
  OmitConsent = "OMIT_CONSENT"
}
