import { Injectable } from '@angular/core';
import { PsPerson } from '@models/ps-person.model';
import { SecurityClass } from '@models/security-class.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';

@Injectable()
export class PopupService {

  constructor(private modalService: NgbModal) { }

  success(title: string, text: string, callback: () => any = () => {}, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'success';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.textOnly = textOnly;
    modal.componentInstance.timer = 1500;
    modal.result.finally(() => callback());
  }

  confirm(title: string, text: string, callback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'confirm';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.textOnly = textOnly;
    modal.result.then((confirm: boolean) => {
      if (confirm) { callback(); }
    });
  }

  confirmCb(title: string, text: string, checkboxText: string, callback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'confirmCb';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.checkboxText = checkboxText;
    modal.componentInstance.textOnly = textOnly;
    modal.result.then((confirm: boolean) => {
      if (confirm) { callback(); }
    });
  }

  schedule(title: string, text: string, callback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'schedule';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.textOnly = textOnly;
    modal.result.then((confirm: boolean) => {
      if (confirm) { callback(); }
    });
  }

  danger(title: string, text: string, callback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'danger';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.textOnly = textOnly;
    modal.result.then((confirm: boolean) => {
      if (confirm) { callback(); }
    });
  }

  code(callback: (code: string) => any) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'code';
    modal.result.then((code: string) => callback(code));
  }

  password(title: string, text: string, callback: (password: string) => any) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'password';
    modal.result.then((password: string) => callback(password));
  }

  policy(callback: () => any = () => {}) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'policy';
    modal.result.finally(() => callback());
  }

  datepicker(title: string, text: string, callback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'datepicker';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.textOnly = textOnly;
    modal.result.then((confirm: boolean) => {
      if (confirm) { callback(); }
    });
  }

  approve(title: string, securityClass: SecurityClass, approveCallback: () => any, denyCallback: () => any, exitCallback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'approve-deny';
    modal.componentInstance.title = title;
    modal.componentInstance.textOnly = textOnly;
    modal.componentInstance.securityClass = securityClass;
    modal.result.then((confirm: boolean) => {
      if (confirm) { 
        approveCallback();
      } else if (confirm === false) {
        denyCallback();
      } else {
        exitCallback();
      }
    }).catch(() => {
      exitCallback();
    });
  }

  approvePsPerson(title: string, text: string, psPerson: PsPerson, approveCallback: () => any, denyCallback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'approve-deny';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.textOnly = textOnly;
    modal.componentInstance.securityClass = psPerson;
    modal.result.then((confirm: boolean) => {
      if (confirm) { 
        approveCallback();
      } else {
        denyCallback();
      }
    });
  }

  revoke(title: string, text: string, psPerson: PsPerson, callback: () => any, textOnly = true) {
    const modal = this.modalService.open(PopupComponent, { centered: true });
    modal.componentInstance.type = 'confirm';
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
    modal.componentInstance.textOnly = textOnly;
    modal.componentInstance.psPerson = psPerson;
    modal.result.then((confirm: boolean) => {
      if (confirm) { 
        callback();
      }
    });
  }
}
