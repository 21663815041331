import { Pipe, PipeTransform } from '@angular/core';
import { DocumentType } from '@enums/document-type.enum';

@Pipe({
  name: 'swedishTranslationDocument'
})
export class SwedishTranslationDocument implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case DocumentType.PS_SECURITY_INTERVIEW: return 'Uppföljande säkerhetsprövningsintervju';
      case DocumentType.PS_SECURITY_CALL_NON_PLACED: return 'Säkerhetssamtal (ej inplacerad personal)';
      case DocumentType.PS_SECURITY_CALL_EVENT_TRIGGERED: return 'Händelseutlöst säkerhetsprövningssamtal';
      case DocumentType.PS_SECURITY_CALL_EXIT: return 'Avslutningssamtal';
      case DocumentType.PS_CERTIFICATE: return 'Intyg';
      case DocumentType.PS_CV: return 'Verifiered tidslinje / CV';
      case DocumentType.PS_REFERENCE_CHECK: return 'Referenstagning';
      case DocumentType.PS_OTHER: return 'Övrigt';
      default: return value;
    }
  }
}
